import { Component } from "react";

const subTitle = "Save The Day";
const title = <h2 className="title">Join on Day Long Free Workshop for <b>Advance</b> <span className="yellow-color">PHP</span> <b>Language</b></h2>;
const desc = "Limited Time Offer! Hurry Up";
const regTitle = "Register Now";
const btnText = "Register Now";

class Register extends Component {
    constructor(props){
        super(props);
        this.state = {
            regName: '',
            regEmail: '',
            regPhone: '',
        };
    }
    render() { 
        return (
            <section className="register-section padding-tb pb-0">
                <div className="container">
                    <div className="row g-4 row-cols-lg-2 row-cols-1 align-items-center">
                        <div className="col">
                            <div className="section-header">
                                <span className="subtitle">{subTitle}</span>
                                {title}
                                <p>{desc}</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="section-wrapper">
                                <h4>{regTitle}</h4>
                                <form className="register-form">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        value={this.state.regName}
                                        onChange={(e)=>{this.setState({regName: e.target.value});}}
                                        placeholder="Username"
                                        className="reg-input"
                                    />
                                    <input
                                        type="email"
                                        name="email"
                                        id="item01"
                                        value={this.state.regEmail}
                                        onChange={(e)=>{this.setState({regEmail: e.target.value});}}
                                        placeholder="Email"
                                        className="reg-input"
                                    />
                                    <input
                                        type="text"
                                        name="number"
                                        id="item01"
                                        value={this.state.regPhone}
                                        onChange={(e)=>{this.setState({regPhone: e.target.value});}}
                                        placeholder="Phone"
                                        className="reg-input"
                                    />
                                    <button type="submit" className="lab-btn"><span>{btnText}</span></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Register;