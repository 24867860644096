import { Component } from "react";


class Rating extends Component {
    render() { 
        return (
            <span className="ratting">
                <i className="icofont-ui-rating"></i>
                <i className="icofont-ui-rating"></i>
                <i className="icofont-ui-rating"></i>
                <i className="icofont-ui-rating"></i>
                <i className="icofont-ui-rating"></i>
            </span>
        );
    }
}
 
export default Rating;