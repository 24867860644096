import { Component } from "react";


const title = "Just Create Your Free Account Now & Join the Workshop";
const btnText = "Join Now";

class WorkshopTwo extends Component {
    constructor(props){
        super(props);
        this.state = {
            workshopUser: '',
            workshopEmail: '',
            workshopPhone: '',
        };
    }
    render() { 
        return (
            <div className="workshop-join">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="workshop-title">
                            <h5>{title}</h5>
                        </div>
                        <div className="workshop-join-form">
                            <form>
                                <div className="input-area">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        value={this.state.workshopUser}
                                        onChange={(e)=>{this.setState({workshopUser: e.target.value});}}
                                        placeholder="Username"
                                    />
                                    <input
                                        type="email"
                                        name="name"
                                        id="item02"
                                        value={this.state.workshopEmail}
                                        onChange={(e)=>{this.setState({workshopEmail: e.target.value});}}
                                        placeholder="Email"
                                    />
                                    <input
                                        type="text"
                                        name="name"
                                        id="item03"
                                        value={this.state.workshopPhone}
                                        onChange={(e)=>{this.setState({workshopPhone: e.target.value});}}
                                        placeholder="Phone"
                                    />
                                </div>
                                <div className="submit-area">
                                    <button type="submit" className="lab-btn"><span>{btnText}</span></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default WorkshopTwo;