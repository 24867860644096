import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";



const title = "Register Now";
const socialTitle = "Register With Social Media";
const btnText = "Get Started Now";


let socialList = [
    {
        link: '#',
        iconName: 'icofont-facebook',
        className: 'facebook',
    },
    {
        link: '#',
        iconName: 'icofont-twitter',
        className: 'twitter',
    },
    {
        link: '#',
        iconName: 'icofont-linkedin',
        className: 'linkedin',
    },
    {
        link: '#',
        iconName: 'icofont-instagram',
        className: 'instagram',
    },
    {
        link: '#',
        iconName: 'icofont-pinterest',
        className: 'pinterest',
    },
]


class SignupPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            regUserName: '',
            regEmail: '',
            regPassword: '',
            regConPassword: '',
        };
    }
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Register Now'} curPage={'Sign Up'} />
                <div className="login-section padding-tb section-bg">
                    <div className="container">
                        <div className="account-wrapper">
                            <h3 className="title">{title}</h3>
                            <form className="account-form">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        value={this.state.regUserName}
                                        onChange={(e)=>{this.setState({regUserName: e.target.value});}}
                                        placeholder="User Name"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        id="item02"
                                        value={this.state.regEmail}
                                        onChange={(e)=>{this.setState({regEmail: e.target.value});}}
                                        placeholder="Email"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="password"
                                        id="item03"
                                        value={this.state.regPassword}
                                        onChange={(e)=>{this.setState({regPassword: e.target.value});}}
                                        placeholder="Password"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="password"
                                        id="item04"
                                        value={this.state.regConPassword}
                                        onChange={(e)=>{this.setState({regConPassword: e.target.value});}}
                                        placeholder="Confirm Password"
                                    />
                                </div>
                                <div className="form-group">
                                    <button className="lab-btn"><span>{btnText}</span></button>
                                </div>
                            </form>
                            <div className="account-bottom">
                                <span className="d-block cate pt-10">Are you a member? <Link to="/login">Login</Link></span>
                                <span className="or"><span>or</span></span>
                                <h5 className="subtitle">{socialTitle}</h5>
                                <ul className="lab-ul social-icons justify-content-center">
                                    {socialList.map((val, i) => (
                                        <li key={i}>
                                            <a href={val.link} className={val.className}><i className={val.iconName}></i></a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}
 
export default SignupPage;