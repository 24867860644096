import { Component } from "react";
import { Link, NavLink } from "react-router-dom";


const phoneNumber = "+800-123-4567 6587";
const address = "Beverley, New York 224 USA";

let socialList = [
    {
        iconName: 'icofont-facebook-messenger',
        siteLink: '#',
    },
    {
        iconName: 'icofont-twitter',
        siteLink: '#',
    },
    {
        iconName: 'icofont-vimeo',
        siteLink: '#',
    },
    {
        iconName: 'icofont-skype',
        siteLink: '#',
    },
    {
        iconName: 'icofont-rss-feed',
        siteLink: '#',
    },
]

class HeaderSeven extends Component {
    menuTrigger() {
        document.querySelector('.menu>ul ').classList.toggle('active')
        document.querySelector('.header-bar').classList.toggle('active')
    }
    menuTriggerTwo() {
        document.querySelector('.header-top').classList.toggle('open')
    }
    
    render() { 
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 200) {
                document.querySelector('.header-section').classList.add(['header-fixed'], ['fadeInUp'])
            }else{
                document.querySelector('.header-section').classList.remove(['header-fixed'], ['fadeInUp'])
            }
        });
        return (
            <header className="header-section style-7">
                <div className="header-top">
                    <div className="container">
                        <div className="header-top-area">
                            <ul className="lab-ul left">
                                <li>
                                    <i className="icofont-ui-call"></i> <span>{phoneNumber}</span>
                                </li>
                                <li>
                                    <i className="icofont-location-pin"></i> {address}
                                </li>
                            </ul>
                            <ul className="lab-ul social-icons d-flex align-items-center">
                                <li><p>Find us on : </p></li>
                                {socialList.map((val, i) => (
                                    <li key={i}><a href={val.siteLink}><i className={val.iconName}></i></a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="header-bottom">
                    <div className="container">
                        <div className="header-wrapper">
                            <div className="logo">
                                <Link to="/"><img src="assets/images/logo/01.png" alt="logo" /></Link>
                            </div>
                            <div className="menu-area">
                                <div className="menu">
                                    <ul className="lab-ul">
                                        <li className="menu-item-has-children">
                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Home</a>
                                            <ul className="lab-ul dropdown-menu">
                                                <li><NavLink to="/">Home One</NavLink></li>
                                                <li><NavLink to="/index-2">Home Two</NavLink></li>
                                                <li><NavLink to="/index-3">Home Three</NavLink></li>
                                                <li><NavLink to="/index-4">Home Four</NavLink></li>
                                                <li><NavLink to="/index-5">Home Five</NavLink></li>
                                                <li><NavLink to="/index-6">Home Six</NavLink></li>
                                                <li><NavLink to="/index-7">Home Seven</NavLink></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Courses</a>
                                            <ul className="lab-ul dropdown-menu">
                                                <li><NavLink to="/course">Course</NavLink></li>
                                                <li><NavLink to="/course-single">Course Details</NavLink></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Blog</a>
                                            <ul className="lab-ul dropdown-menu">
                                                <li><NavLink to="/blog">Blog Grid</NavLink></li>
                                                <li><NavLink to="/blog-2">Blog Style 2</NavLink></li>
                                                <li><NavLink to="/blog-3">Blog Style 3</NavLink></li>
                                                <li><NavLink to="/blog-single">Blog Single</NavLink></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Pages</a>
                                            <ul className="lab-ul dropdown-menu">
                                                <li><NavLink to="/about">About</NavLink></li>
                                                <li><NavLink to="/team">Team</NavLink></li>
                                                <li><NavLink to="/instructor">Instructor</NavLink></li>
                                                <li><NavLink to="/shop">Shop Page</NavLink></li>
                                                <li><NavLink to="/shop-single">Shop Details Page</NavLink></li>
                                                <li><NavLink to="/cart-page">Shop Cart Page</NavLink></li>
                                                <li><NavLink to="/search-page">Search Page</NavLink></li>
                                                <li><NavLink to="/search-none">Search None</NavLink></li>
                                                <li><NavLink to="/404">404</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><NavLink to="/contact">Contact</NavLink></li>
                                    </ul>
                                </div>
                                
                                <Link to="/login" className="login"><i className="icofont-user"></i> <span>LOG IN</span> </Link>
                                <Link to="/signup" className="signup"><i className="icofont-users"></i> <span>SIGN UP</span> </Link>

                                <div className="header-bar d-lg-none" onClick={this.menuTrigger}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div className="ellepsis-bar d-lg-none" onClick={this.menuTriggerTwo}>
                                    <i className="icofont-info-square"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
 
export default HeaderSeven;